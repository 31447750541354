export function useUrls() {
  const config = useRuntimeConfig()

  return {
    onboardingFINUrl: '/rendement-locatif',

    cleEnMainServiceUrl:
      'https://horiz.io/ressources/investissement-locatif-cle-en-main?utm_source=horiz&utm_medium=hero&utm_campaign=cle-en-main',
    cleEnMainServiceUrl2:
      'https://horiz.io/ressources/investissement-locatif-cle-en-main?utm_source=horiz&utm_medium=hero-customer&utm_campaign=cle-en-main',

    realEstatesUrl() {
      return `${config.public.appBaseUrl}/real-estates`
    },

    onboardingPINUrl(noRedirection = false, redirect = window.location.href) {
      return noRedirection
        ? `${config.public.appBaseUrl}/onboarding/investor`
        : `${config.public.appBaseUrl}/onboarding/investor?redirectUri=${redirect}`
    },

    notificationPushCheckUrl() {
      return `${config.public.appBaseUrl}/notification-push-check`
    },

    notificationPushParametersUrl() {
      return `${config.public.appBaseUrl}/notification-push-parameters`
    },
  }
}
